import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { className, isNonEmptyArray } from 'utils';
import {
  ArticleSocialMediaBar,
  ArticleDisclosure,
  ArticleGrid,
  ArticleInfo,
  ArticleTopper,
  BodyContent,
  ContactForm,
  RecircList,
  TopicList,
  SEO,
  CTA,
} from 'components';
import { Pathways } from 'svgs';

import { HSFORM_EMAIL_SUBSCRIPTION, RECIRC_LIST_FORMAT } from 'utils/constants';
import get from 'lodash.get';
import { articlePropTypes } from 'utils/proptypes';
import styles from './article.module.scss';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';

const Article = ({ data: { article, allTopics, recentFromTopic, global }, location }) => {
  const {
    content,
    cta,
    hideFooterCta,
    seo,
    author,
    topics,
    title,
    date,
    publishDate,
    articleImage,
    slug,
    showRelatedArticles,
    showRelatedArticlesFooter,
    headline,
    featuredArticles,
    footerFeaturedArticles,
  } = article;

  console.log('footerFeaturedArticles', footerFeaturedArticles);
  const { articleFooterCta: globalArticleFooterCta, disclosureText: globalDisclosureText, url: globalUrl } = global;

  const topicList = allTopics.edges.map(({ node }) => node);

  const articleCta = cta ? cta : globalArticleFooterCta;

  const firstTopicWithImage =
    isNonEmptyArray(topics) && topics.find(({ image }) => get(image, 'file.url'));
  const shareImage = articleImage ? articleImage : get(firstTopicWithImage, 'image');

  const firstTopicWithCustomDisclosure =
    isNonEmptyArray(topics) && topics.find(topic => get(topic, 'customDisclosureText'));
  const disclosureText =
    get(firstTopicWithCustomDisclosure, 'customDisclosureText') || globalDisclosureText;

  const relatedArticlesOverline = 'You may also be interested in...';

  const recentFromTopicList = recentFromTopic.edges.map(({ node }) => node);

  const hasSidebarArticles = featuredArticles || recentFromTopicList;
  const hasFooterArticles = footerFeaturedArticles || recentFromTopicList;

  const getSideArticles = max => {
    // if showRelatedArticles is set to true, or default to true if field isn't set in CMS
    if (showRelatedArticles !== false && hasSidebarArticles) {
      const list = [];
      featuredArticles?.forEach(insight => {
        //add featured articles from CMS
        list.push(insight);
      });
      recentFromTopicList?.forEach(insight => {
        //add articles that are not the current article
        insight.slug !== slug && list.push(insight);
      });
      // Future enhancement: get top hit related articles
      // const mostPopularFromTopic = ???;
      return list.slice(0, max);
    } else {
      return false;
    }
  };

  const getFooterArticles = max => {
    if (showRelatedArticlesFooter && hasFooterArticles) {
      const list = [];
      footerFeaturedArticles?.forEach(insight => {
        //add featured articles from CMS
        list.push(insight);
      });
      recentFromTopicList?.forEach(insight => {
        //add articles that are not the current article
        insight.slug !== slug && list.push(insight);
      });
      // Future enhancement: get top hit related articles
      // const mostPopularFromTopic = ???;
      return list.slice(0, max);
    } else {
      return false;
    }
  };

  const { transitionStatus } = useTransitionState();

  useEffect(() => {
    const formattedDate = date.split('.').join('/');
    const publishDate = new Date(formattedDate).toISOString().slice(0, 10);
    window.dataLayer.push({
      author: author?.name,
      publishDate: publishDate,
    });

    return () => {
      window.dataLayer.push({
        author: null,
        publishDate: null,
      });
    };
  }, []);

  return (
    <article id="articleBody" data-track-author={author?.name} data-track-publishDate={publishDate}>
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}
      />
      <SEO {...seo} title={title} shareImage={shareImage} pathname={location.pathname} />
      <ArticleTopper article={article} featured={false} />
      <ArticleSocialMediaBar globalUrl={globalUrl} articleTitle={title} />
      <div {...className(styles.contentWrapper, shareImage ? styles.withFeaturedImage : '')}>
        {content && (
          <BodyContent
            content={content}
            location={location}
            topics={topicList}
            hasFeaturedImage={shareImage && true}
          />
        )}
        <div id="sidebarBottomLine" />
        {hasFooterArticles && showRelatedArticlesFooter && (
          <div
            {...className(styles.relatedArticleFooter, styles.contentWrapper, 'component')}
            data-automation-id="related-article-footer">
            <Pathways className={styles.relatedArticleFooterBg} />
            {headline && <h3>{headline}</h3>}
            {/* <h3>Related Insights</h3> */}
            <ArticleGrid
              articles={getFooterArticles(4)}
              className={styles.relatedArticleFooterGrid}
              relatedArticle
            />
          </div>
        )}
        <ArticleInfo
          className={styles.articleInfo}
          articleTitle={title}
          author={author}
          topics={topics}
          date={date}
        />
        <ArticleDisclosure className={styles.articleDisclosure} disclosureText={disclosureText} />
        <aside className={styles.rightGutter}>
          <TopicList className={styles.topicList} topics={topicList} />
          {transitionStatus === 'entered' && (
            <ContactForm
              title={HSFORM_EMAIL_SUBSCRIPTION.TITLE}
              hubspotFormId={HSFORM_EMAIL_SUBSCRIPTION.FORMID}
              displayTitle={true}
              format={'Float Right'}
              uid={HSFORM_EMAIL_SUBSCRIPTION.FORMID}
              customClassName={styles.articleIndexSubscription}
              showLoading={false}
            />
          )}
          {hasSidebarArticles && showRelatedArticles !== false && (
            <RecircList
              overline={relatedArticlesOverline}
              listItems={getSideArticles(3)}
              format={RECIRC_LIST_FORMAT.TEASER}
            />
          )}
        </aside>
      </div>
      {!hideFooterCta && <CTA {...articleCta} onFooter />}
    </article>
  );
};

Article.propTypes = {
  data: PropTypes.shape({
    article: articlePropTypes,
    global: PropTypes.object,
    recentFromTopic: PropTypes.object,
    allTopics: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Article;

export const query = graphql`
  fragment TEASER on ContentfulArticle {
    title
    date
    slug
    intro {
      intro
    }
    articleImage {
      file {
        url
      }
    }
    featuredImage {
      title
      description
      file {
        url
      }
    }
    topics {
      name
      image {
        file {
          url
        }
      }
      slug
    }
  }

  query articleQuery($slug: String!, $topicSlug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      displayIntroOnArticlePage
      intro {
        intro
      }
      author {
        name
        title
        description {
          description
        }
        linkText
        linkUrl
        image {
          description
          title
          file {
            url
          }
        }
      }
      topics {
        slug
        name
        image {
          title
          file {
            url
          }
        }
        slug
        customDisclosureText {
          json
        }
      }
      date(formatString: "MM.DD.YY")
      publishDate: date(formatString: "YYYY-MM-DD")
      content {
        json
      }
      articleImage {
        description
        title
        file {
          url
        }
      }
      featuredImage {
        description
        title
        file {
          url
        }
      }
      showRelatedArticles
      featuredArticles {
        ...TEASER
      }
      showRelatedArticlesFooter
      headline
      footerFeaturedArticles {
        ... on ContentfulArticle {
          ...TEASER
        }
        ... on ContentfulColumnCtaItem {
          ctaType
          headline
          ctaUrl
          label
          labelUrl
          intro {
            intro
          }
          image {
            description
            title
            file {
              url
            }
          }

        }

      }
      cta {
        ...CTA
      }
      hideFooterCta
      seo {
        ...SEO
      }
    }

    allTopics: allContentfulTopic(sort: { fields: name }) {
      edges {
        node {
          name
          slug
        }
      }
    }

    recentFromTopic: allContentfulArticle(
      limit: 5
      sort: { fields: date, order: DESC }
      filter: { topics: { elemMatch: { slug: { eq: $topicSlug } } } }
    ) {
      edges {
        node {
          ...TEASER
        }
      }
    }

    global: contentfulGlobal {
      articleFooterCta {
        ...CTA
      }
      disclosureText {
        json
      }
      url
    }
  }
`;
